import React from "react"
import { graphql } from "gatsby"
import get from "lodash/get"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import linkBuilder from "../../modules/linkBuilder"

/* Components */
import Layout from "../../components/layout"
import Seo from "../../components/seo/seo"
import SocialMeta from "../../components/social-meta/social-meta"
import StickyNav from "../../components/sticky-nav/sticky-nav"
import Footer from "../../components/footer/footer"
import RichTextContent from "../../components/rich-text-content/rich-text-content"

/* Styles */
import {
  backgroundImage,
  authorContent,
  authorImageWrapper,
  authorPhoto,
  authorContentInner,
  social,
  intro,
} from "./author.module.css"
import { FaFacebook, FaGlobe, FaLinkedinIn, FaTwitter } from "react-icons/fa"
import { Helmet } from "react-helmet"

class AuthorTemplate extends React.Component {
  render() {
    const desks = get(this.props, "pageContext.desks")
    const image = getImage(this.props.data.file.childImageSharp)
    const bgImage = convertToBgImage(image)

    const author = get(this.props, "data.contentfulAuthor")
    const description = get(
      author,
      "description.childMarkdownRemark.rawMarkdownBody"
    )
    const pageUrl = `https://www.mkbservicedesk.nl/${linkBuilder.author(
      author
    )}`
    const ldJson = {
      "@context": "https://schema.org",
      "@type": "Person",
      name: author.name,
      url: pageUrl,
    }

    const sameAs = []
    if (author.linkedinUrl) sameAs.push(author.linkedinUrl)
    if (author.twitterUrl) sameAs.push(author.twitterUrl)
    if (author.facebookUrl) sameAs.push(author.facebookUrl)
    if (author.websiteUrl) sameAs.push(author.websiteUrl)
    if (sameAs.length) ldJson.sameAs = sameAs
    if (author.image?.gatsbyImageData) ldJson.image = author.image.gatsbyImageData.images.fallback.src
    if (author.description) ldJson.description = author.description

    return (
      <Layout location={this.props.location}>
        <Seo title={author.name} description={description} />
        <SocialMeta
          title={author.name}
          description={description}
          url={linkBuilder.author(author)}
          image={author.image && author.image.gatsbyImageData && author.image.gatsbyImageData.images.fallback.src}
        />
        <Helmet>
          <link rel="canonical" href={pageUrl} />
          <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
        </Helmet>
        <header className="header">
          <StickyNav desks={desks} />
          <div className={backgroundImage}>
            <BackgroundImage
              className={backgroundImage}
              {...bgImage}
            ></BackgroundImage>
          </div>
        </header>
        <main className="main author">
          <div className="container">
            <section className={authorContent}>
              <div className={authorImageWrapper}>
                {author.image && (
                  <GatsbyImage
                    className={authorPhoto}
                    image={author.image.gatsbyImageData}
                    alt={author.name}
                  />
                )}
              </div>

              <div className={authorContentInner}>
                <h1>{author.name}</h1>

                {author.linkedinUrl && (
                  <a
                    className={social}
                    href={author.linkedinUrl}
                    rel="noopener noreferrer"
                    alt={`LinkedIn profiel ${author.name}`}
                  >
                    <FaLinkedinIn />
                  </a>
                )}
                {author.twitterUrl && (
                  <a
                    className={social}
                    href={author.twitterUrl}
                    rel="noopener noreferrer"
                    alt={`Twitter profiel ${author.name}`}
                  >
                    <FaTwitter />
                  </a>
                )}
                {author.facebookUrl && (
                  <a
                    className={social}
                    href={author.facebookUrl}
                    rel="noopener noreferrer"
                    alt={`Facebook profiel ${author.name}`}
                  >
                    <FaFacebook />
                  </a>
                )}
                {author.websiteUrl && (
                  <a
                    className={social}
                    href={author.websiteUrl}
                    rel="noopener noreferrer"
                    alt={`Persoonlijke website ${author.name}`}
                  >
                    <FaGlobe />
                  </a>
                )}

                {description && <p className={intro}>{description}</p>}

                {author.body && (
                  <div className="richtext">
                    <RichTextContent
                      content={author.body}
                      addListMarker={true}
                      displayPagePath={linkBuilder.author(author)}
                    />
                  </div>
                )}
              </div>
            </section>
          </div>
        </main>
        <Footer />
      </Layout>
    )
  }
}

export default AuthorTemplate

export const pageQuery = graphql`
  query AuthorBySlug($slug: String!) {
    file(relativePath: { eq: "auteur-typen-toetsenbord.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1180)
      }
    }

    site {
      siteMetadata {
        title
      }
    }

    contentfulAuthor(slug: { eq: $slug }) {
      slug
      name
      communityProfileUrl
      linkedinUrl
      twitterUrl
      facebookUrl
      websiteUrl
      description {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      image {
        gatsbyImageData(layout: FIXED, width: 160, height: 160)
      }
      body {
        raw
        references {
          __typename
          ... on ContentfulVideo {
            url
          }
        }
      }
    }
  }
`
